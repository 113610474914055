'use client';

import React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import IconWrapper from './IconWrapper';

interface Props extends React.HTMLAttributes<SVGElement> {
  size?: number;
  isBooped: boolean;
}

export function IconRSS({
  size = 20,
  isBooped,
  ...delegated
}: Props) {
  const circleSpring = useSpring({
    r: isBooped ? 2 : 1,
    config: {
      tension: 300,
      friction: 22,
    },
  });

  const i = 2;
  const innerRingSpring = useSpring({
    d: isBooped
      ? `M4 ${11 - i} a ${9 + i} ${9 + i} 0 0 1 ${9 + i} ${9 + i}`
      : 'M4 11 a 9 9 0 0 1 9 9',
    config: {
      tension: 300,
      friction: 18,
    },
    delay: 16.6 * 4,
  });
  const o = 2;
  const outerRingSpring = useSpring({
    d: isBooped
      ? `M4 ${4 - o} a ${16 + o} ${16 + o} 0 0 1 ${16 + o} ${16 + o}`
      : 'M4 4 a 16 16 0 0 1 16 16',
    config: {
      tension: 250,
      friction: 12,
    },
    delay: 16.6 * 8,
  });

  return (
    <Svg
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
    >
      <animated.path {...innerRingSpring} />
      <animated.path {...outerRingSpring} />
      <animated.circle cx="5" cy="19" {...circleSpring} />
    </Svg>
  );
}

function RSSLink(props: { size?: number }) {
  return (
    <IconWrapper
      alt="RSS Feed"
      // Don't prefetch the big XML file!
      prefetch={false}
      // Hm for some reason the `prefetch` prop doesn't seem to work. I should also be able to work around this by using a full URL, rather than a relative one.
      // I'll keep using the local one in development, for convenient developing.
      href={
        process.env.NODE_ENV === 'development'
          ? '/rss.xml'
          : 'https://joshwcomeau.top/rss.xml'
      }
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {({ isBooped }) => <IconRSS isBooped={isBooped} />}
    </IconWrapper>
  );
}

const Svg = styled.svg`
  stroke: currentColor;
  display: block;
  overflow: visible;

  circle {
    fill: currentColor;
  }
`;

export default RSSLink;
